import React from 'react';
import ReactModal from 'react-modal';
import { IntercomProvider } from 'react-use-intercom';
import { Helmet } from 'react-helmet';
import { Script } from 'gatsby';
import { PageContext } from './src/contexts';
import LangProvider from './src/providers/langProvider';

export const wrapPageElement = ({ element, props }) => {
  ReactModal.setAppElement(document.getElementById('___gatsby'));

  return (
    <>
      <IntercomProvider appId={process.env.GATSBY_INTERCOM_APP_ID} autoBoot>
        <PageContext.Provider value={props.pageContext}>
          <LangProvider>{element}</LangProvider>
        </PageContext.Provider>
      </IntercomProvider>

      {process.env.GATSBY_ACTIVE_ENV === 'production' && (
        <>
          <Script
            id="pixel-facebook"
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '123507161419527');fbq('track', 'PageView');`,
            }}
          />
          <Script
            id="clickcease"
            dangerouslySetInnerHTML={{
              __html: `var clickceaseScript=document.getElementById("clickcease");if(!clickceaseScript){var c=document.createElement("script");c.async=!0,c.type="text/javascript";var e,t="https://www.clickcease.com/monitor/stat.js";c.src=t,c.id="clickcease",document.head.appendChild(c)}`,
            }}
          />
          <Script
            id="btob"
            dangerouslySetInnerHTML={{
              __html: `(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}}(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-6342847E66F22"));`,
            }}
          />
          <Helmet>
            <noscript>
              {`<a href="https://www.clickcease.com" rel="nofollow"><img src="https://monitor.clickcease.com/stats/stats.aspx" alt="ClickCease"/></a>`}
            </noscript>
            <noscript>
              {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=123507161419527&ev=PageView&noscript=1"/>`}
            </noscript>
            <noscript>
              {`<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_DATOCMS_GTM_TAG}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
            </noscript>
          </Helmet>
        </>
      )}
    </>
  );
};
