import React, { useContext } from 'react';
import LangContext from 'contexts/lang';
import { PageContext } from 'contexts';
import { URL_LOCALES } from '../constants';

const LangProvider = ({ children }) => {
  const { locale: language } = useContext(PageContext);

  const languages =
    URL_LOCALES.find(({ locale }) => locale === language)?.languages || [];

  const getLocalUrlSegment = (segments, selectedLanguage) => {
    const splitSegments = segments.split('.');

    const localeObject = URL_LOCALES?.find(
      ({ locale }) =>
        locale === (selectedLanguage ? selectedLanguage : language),
    );
    const URL = splitSegments.reduce((acc, value) => {
      if (typeof acc === 'object') {
        return acc[value];
      }
      return localeObject[acc][value];
    });
    if (!Boolean(URL)) {
      return segments;
    }
    return URL;
  };

  return (
    <LangContext.Provider
      value={{
        language,
        getLocalUrlSegment,
        languages,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export default LangProvider;
