export const LANGUAGES = ['fr', 'en', 'nl'];

if (typeof navigator !== 'undefined') {
  var lang = navigator.language === 'fr-FR' ? 'fr' : navigator.language;
}

export const DEFAULT_LANGUAGE = `${
  lang === 'fr' || lang === 'en' ? lang : 'fr'
}`;

export const URL_LOCALES = [
  {
    locale: 'fr',
    url: {
      activity: 'activité',
      landing: 'atterrissage',
      company: 'entreprises',
      school: 'ecoles-et-collectivites',
      catering: 'restauration',
      faq: 'faq',
    },
    navbar: {
      company: 'Entreprises',
      school: 'Ecoles et collectivités',
      catering: 'Restauration',
      home: 'Accueil',
    },
    languages: [
      { label: 'Français', locale: 'fr' },
      { label: 'Anglais', locale: 'en' },
      { label: 'Néerlandais', locale: 'nl' },
    ],
  },
  {
    locale: 'en',
    url: {
      activity: 'activity',
      landing: 'landing',
      company: 'company',
      school: 'school-and-collectivity',
      catering: 'catering',
      faq: 'faq',
    },
    navbar: {
      company: 'Company',
      school: 'School and collectivity',
      catering: 'Catering',
      home: 'Home',
    },
    languages: [
      { label: 'French', locale: 'fr' },
      { label: 'English', locale: 'en' },
      { label: 'Dutch', locale: 'nl' },
    ],
  },
  {
    locale: 'nl',
    url: {
      activity: 'activity',
      landing: 'landing',
      company: 'company',
      school: 'school-and-collectivity',
      catering: 'catering',
      faq: 'faq',
    },
    navbar: {
      company: 'Company',
      school: 'School and collectivity',
      catering: 'Catering',
      home: 'Home',
    },
    languages: [
      { label: 'French', locale: 'fr' },
      { label: 'English', locale: 'en' },
      { label: 'Dutch', locale: 'nl' },
    ],
  },
];

const KOEZIO_LILLE_JSON_LD_SCHEMA = {
  '@context': 'http://www.schema.org',
  '@type': 'EntertainmentBusiness',
  name: 'Koezio Lille',
  logo: 'https://www.datocms-assets.com/33185/1597935484-logo-koezio.jpg',
  description:
    "Découvrez ou re-découvrez les expériences Koezio proposées sur le parc de Villeneuve d'Ascq : escape game, karaoké, bar et restaurant. L'endroit idéal pour vos sorties entre amis ou en famille à deux pas de Lille.",
  url: 'https://koezio.co/koezio-lille',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '31 rue Alfred de Musset',
    addressLocality: "Villeneuve d'ascq",
    addressRegion: 'Hauts-de-France',
    postalCode: '59650',
    addressCountry: 'France',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '50.648183273614954',
    longitude: '3.140665100139858',
  },
  hasMap:
    "https://www.google.com/maps/place/Koezio+Lille/@50.6482813,3.1403397,17z/data=!4m13!1m7!3m6!1s0x47c329ce32002833:0x413377b439f7c3b4!2s31+Rue+Alfred+de+Musset,+59650+Villeneuve-d'Ascq!3b1!8m2!3d50.6482813!4d3.1403397!3m4!1s0x47c329ce324f8d43:0xdd9ba63cf7c4b0a4!8m2!3d50.6480555!4d3.1407046",
  openingHours:
    'Mo 10:00-22:00 Tu 10:00-22:00 We 10:00-22:00 Th 10:00-22:00 Fr 10:00-22:00 Sa 10:00-22:00',
  telephone: '+33 0320058000',
  email: 'infos-lille@koezio.co',
  currenciesAccepted: 'EUR',
  paymentAccepted:
    'Carte de crédit, bons cadeaux, bons CE (website) / Carte de crédit, chèque vacances, bons cadeaux, cash (sur place)',
  founder: { name: 'Bertrand DELGRANGE' },
  foundingDate: '2006',
  keywords: 'Agents d’Elite, Hors Contrôle, Karaoké Club',
  sameAs: [
    'https://www.instagram.com/koezio_lille/',
    'https://www.facebook.com/KoezioGroup',
    'https://www.linkedin.com/company/koezio/mycompany/',
    'https://www.tripadvisor.fr/Attraction_Review-g196640-d1841828-Reviews-Koezio-Villeneuve_d_Ascq_Lille_Nord_Hauts_de_France.html',
    'https://www.tiktok.com/@koeziogroup',
  ],
};

const KOEZIO_SENART_JSON_LD_SCHEMA = {
  '@context': 'http://www.schema.org',
  '@type': 'EntertainmentBusiness',
  name: 'Koezio Sénart',
  logo: 'https://www.datocms-assets.com/33185/1597935484-logo-koezio.jpg',
  description:
    "Découvrez ou re-découvrez les expériences Koezio proposées sur le parc de Sénart Lieusaint : escape game, karaoké, réalité virtuelle, bar et restaurant. L'endroit idéal pour vos sorties entre amis ou en famille à deux pas du Carré Sénart.",
  url: 'https://koezio.co/senart',
  address: {
    '@type': 'PostalAddress',
    streetAddress:
      "Centre commercial régional du Carré Sénart, 5-7 Trait d'Union",
    addressLocality: 'Lieusaint',
    addressRegion: 'Île-de-France',
    postalCode: '77127',
    addressCountry: 'France',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '48.721057883941015',
    longitude: '2.5226032695487626',
  },
  hasMap:
    'https://www.google.com/maps/place/Koezio+S%C3%A9nart/@48.6217894,2.5427322,15z/data=!4m12!1m6!3m5!1s0x0:0x21280e9362d51ec4!2sKoezio+S%C3%A9nart!8m2!3d48.6217894!4d2.5427322!3m4!1s0x0:0x21280e9362d51ec4!8m2!3d48.6217894!4d2.5427322',
  openingHours:
    'Mo 10:00-22:00 Tu 10:00-22:00 We 10:00-22:00 Th 10:00-22:00 Fr 10:00-22:00 Sa 10:00-22:00',
  telephone: '+33 0164131300',
  email: 'infos-senart@koezio.co',
  currenciesAccepted: 'EUR',
  paymentAccepted:
    'Carte de crédit, bons cadeaux, bons CE (website) / Carte de crédit, chèque vacances, bons cadeaux, cash (sur place)',
  founder: { name: 'Bertrand DELGRANGE' },
  foundingDate: '2012',
  keywords: 'Agents d’Elite, Hors Contrôle, Icecube Protocol',
  sameAs: [
    'https://www.instagram.com/koezio_senart/',
    'https://www.facebook.com/KoezioGroup',
    'https://www.linkedin.com/company/koezio/mycompany/',
    'https://www.tripadvisor.fr/Attraction_Review-g1210801-d4324783-Reviews-Koezio-Lieusaint_Seine_et_Marne_Ile_de_France.html',
    'https://www.tiktok.com/@koeziogroup',
  ],
};

const KOEZIO_CERGY_JSON_LD_SCHEMA = {
  '@context': 'http://www.schema.org',
  '@type': 'EntertainmentBusiness',
  name: 'Koezio Cergy',
  url: 'https://koezio.co/cergy',
  logo: 'https://www.datocms-assets.com/33185/1597935484-logo-koezio.jpg',
  description:
    "Découvrez ou re-découvrez les expériences Koezio proposées sur le parc de Cergy : escape game, karaoké, réalité virtuelle, bar et restaurant. L'endroit idéal pour vos sorties entre amis ou en famille !",
  address: {
    '@type': 'PostalAddress',
    streetAddress: '11 Av. de la Plaine des Sports',
    addressLocality: 'Cergy',
    addressRegion: 'Île-de-France',
    postalCode: '95800',
    addressCountry: 'France',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '49.05344699030406',
    longitude: '2.053783128605181',
  },
  hasMap:
    'https://www.google.com/maps/place/Koezio+Cergy/@49.0531552,2.0515086,17z/data=!3m1!4b1!4m5!3m4!1s0x47e6f4f20cb963d9:0x2005415ea0d44870!8m2!3d49.0532688!4d2.0538112',
  openingHours:
    'Mo 10:00-22:00 Tu 10:00-22:00 We 10:00-22:00 Th 10:00-22:00 Fr 10:00-22:00 Sa 10:00-22:00',
  telephone: '+33 0134434200',
  email: 'infos-cergy@koezio.co',
  currenciesAccepted: 'EUR',
  paymentAccepted:
    'Carte de crédit, bons cadeaux, bons CE (website) / Carte de crédit, chèque vacances, bons cadeaux, cash (sur place)',
  founder: { name: 'Bertrand DELGRANGE' },
  foundingDate: '2016',
  keywords:
    'Agents d’Elite, Hors Contrôle, Musi’Quiz, Icecube Protocol, Karaoké Club, Machine Infernale',
  sameAs: [
    'https://www.instagram.com/koeziocergy/',
    'https://www.facebook.com/KoezioGroup',
    'https://www.linkedin.com/company/koezio/mycompany/',
    'https://www.tripadvisor.fr/Attraction_Review-g262037-d10198076-Reviews-Koezio-Cergy_Cergy_Pontoise_Val_d_Oise_Ile_de_France.html',
    'https://www.tiktok.com/@koeziogroup',
  ],
};

const KOEZIO_BRUSSEL_JSON_LD_SCHEMA = {
  '@context': 'http://www.schema.org',
  '@type': 'EntertainmentBusiness',
  name: 'Koezio Brussel',
  url: 'https://koezio.co/bruxelles',
  logo: 'https://www.datocms-assets.com/33185/1597935484-logo-koezio.jpg',
  description:
    "Découvrez ou re-découvrez les expériences Koezio proposées sur le parc de Bruxelles : escape game, karaoké, réalité virtuelle, bar et restaurant. L'endroit idéal pour vos sorties entre amis ou en famille !",
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Bd Lambermont 1',
    addressLocality: 'Bruxelles',
    addressRegion: 'Bruxelles-Capitale',
    postalCode: '1000',
    addressCountry: 'Belgium',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '50.648183273614954',
    longitude: '3.140665100139858',
  },
  hasMap:
    'https://www.google.com/maps/place/Koezio+Brussel/@50.8788213,4.3698972,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3c3050e347289:0xa52cfd504f4f8914!8m2!3d50.8788179!4d4.3720859',
  openingHours:
    'Mo 10:00-22:00 Tu 10:00-22:00 We 10:00-22:00 Th 10:00-22:00 Fr 10:00-22:00 Sa 10:00-22:00',
  telephone: '+3223195454',
  email: 'infos@koezio.be',
  currenciesAccepted: 'EUR',
  paymentAccepted:
    'Carte de crédit, bons cadeaux, bons CE (website) / Carte de crédit, chèque vacances, bons cadeaux, cash (sur place)',
  founder: { name: 'Bertrand DELGRANGE' },
  foundingDate: '2016',
  keywords: 'Agents d’Elite, Karaoké Club',
  sameAs: [
    'https://www.instagram.com/koeziobruxsel/',
    'https://www.facebook.com/KoezioBrussels/',
    'https://www.linkedin.com/company/koezio/mycompany/',
    'https://www.tripadvisor.fr/Attraction_Review-g188644-d11789089-Reviews-Koezio-Brussels.html',
    'https://www.tiktok.com/@koeziobruxsel',
  ],
};

const KOEZIO_LYON_JSON_LD_SCHEMA = {
  '@context': 'http://www.schema.org',
  '@type': 'EntertainmentBusiness',
  name: 'Koezio Brussel',
  url: 'https://koezio.co/lyon',
  logo: 'https://www.datocms-assets.com/33185/1597935484-logo-koezio.jpg',
  description:
    'Jouez, partagez, recommencez ! Bienvenue dans votre parc indoor Koezio à Saint-Priest. Venez découvrir des activités uniques pour toute la famille à seulement 30 minutes Lyon !',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '50 Anc. Rte de Grenoble',
    addressLocality: 'Saint-Priest',
    addressRegion: 'Auvergne-Rhône-Alpes',
    postalCode: '69800',
    addressCountry: 'France',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '45.80978583105832',
    longitude: '4.970894557157158',
  },
  hasMap:
    'https://www.google.com/maps/place/Koezio+Lyon/@45.7254885,4.935747,17z/data=!3m1!4b1!4m5!3m4!1s0x47f4c1599d2f769b:0x599fc1fadfaadb1!8m2!3d45.7253274!4d4.9380019',
  openingHours:
    'Mo 10:00-22:00 Tu 10:00-22:00 We 10:00-22:00 Th 10:00-22:00 Fr 10:00-22:00 Sa 10:00-22:00',
  telephone: '+33 0428291515',
  email: 'infos-lyon@koezio.co',
  currenciesAccepted: 'EUR',
  paymentAccepted:
    'Carte de crédit, bons cadeaux, bons CE (website) / Carte de crédit, chèque vacances, bons cadeaux, cash (sur place)',
  founder: { name: 'Bertrand DELGRANGE' },
  foundingDate: '2019',
  keywords: 'Agents d’Elite, Karaoké Club',
  sameAs: [
    'https://www.instagram.com/koezio_lyon/',
    'https://www.facebook.com/KoezioGroup',
    'https://www.linkedin.com/company/koezio/mycompany/',
    'https://www.tripadvisor.fr/Attraction_Review-g1166685-d17622793-Reviews-KOEZIO_Lyon-Saint_Priest_Rhone_Auvergne_Rhone_Alpes.html',
    'https://www.tiktok.com/@koeziogroup',
  ],
};

export const PARKS_JSON_LD_SCHEMA = {
  'koezio-lille': KOEZIO_LILLE_JSON_LD_SCHEMA,
  senart: KOEZIO_SENART_JSON_LD_SCHEMA,
  cergy: KOEZIO_CERGY_JSON_LD_SCHEMA,
  bruxelles: KOEZIO_BRUSSEL_JSON_LD_SCHEMA,
  lyon: KOEZIO_LYON_JSON_LD_SCHEMA,
};

export const HOMEPAGE_JSON_LD_SCHEMA = {
  '@context': 'http://www.schema.org',
  '@type': 'Organization',
  name: 'KOEZIO',
  url: 'https://koezio.co',
  logo: 'https://www.datocms-assets.com/33185/1597935484-logo-koezio.jpg',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '31 rue Alfred de Musset',
    addressLocality: "Villeneuve d'ascq",
    addressRegion: 'Hauts-de-France',
    postalCode: '59650',
    addressCountry: 'France',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '50.648183273614954',
    longitude: '3.140665100139858',
  },
  hasMap:
    "https://www.google.com/maps/place/Koezio+Lille/@50.6482813,3.1403397,17z/data=!4m13!1m7!3m6!1s0x47c329ce32002833:0x413377b439f7c3b4!2s31+Rue+Alfred+de+Musset,+59650+Villeneuve-d'Ascq!3b1!8m2!3d50.6482813!4d3.1403397!3m4!1s0x47c329ce324f8d43:0xdd9ba63cf7c4b0a4!8m2!3d50.6480555!4d3.1407046",
  brand: 'KOEZIO / LUCKY FOLKS',
  email: 'contact@koezio.co',
  founder: { name: 'Bertrand DELGRANGE' },
  foundingDate: '2006',
  sameAs: [
    'https://www.instagram.com/koezio/',
    'https://www.facebook.com/KoezioGroup',
    'https://www.linkedin.com/company/koezio/mycompany/',
    'https://www.tiktok.com/@koeziogroup',
  ],
};
