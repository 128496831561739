exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-b-2-b-js": () => import("./../../../src/templates/b2b.js" /* webpackChunkName: "component---src-templates-b-2-b-js" */),
  "component---src-templates-b-2-i-js": () => import("./../../../src/templates/b2i.js" /* webpackChunkName: "component---src-templates-b-2-i-js" */),
  "component---src-templates-catering-js": () => import("./../../../src/templates/catering.js" /* webpackChunkName: "component---src-templates-catering-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-park-js": () => import("./../../../src/templates/park.js" /* webpackChunkName: "component---src-templates-park-js" */)
}

